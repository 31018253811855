.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}





.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Corrected keyframes for floatingGlow */
@keyframes floatingGlow {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, calc(-50% - 20px));
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

/* Corrected keyframes for floatingGlowReverse */
@keyframes floatingGlowReverse {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(calc(-50% + 20px), -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}


/* Now target your glow backgrounds in CSS */
.glow1 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  background: radial-gradient(circle, rgba(162, 89, 255, 0.6), transparent);
  filter: blur(150px);
  z-index: 0;
  animation: floatingGlow 10s infinite ease-in-out;
  transform: translate(-50%, -50%);
}

.glow2 {
  position: absolute;
  top: 70%;
  left: 30%;
  width: 50%;
  height: 50%;
  background: radial-gradient(circle, rgba(255, 94, 58, 0.6), transparent);
  filter: blur(180px);
  z-index: 0;
  animation: floatingGlowReverse 15s infinite ease-in-out;
  transform: translate(-50%, -50%);
}




body {
  font-family: 'Hanken Grotesk', sans-serif;
}
