body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 768px) {
  .benefits-section {
    flex-direction: column; /* Stack the cards vertically on mobile */
    align-items: center;
    padding:20px; /* Add padding to reduce the gap on mobile */
  }

  .benefits-section div {
    width: 90%; /* Ensure cards take full width on mobile */
    margin-bottom: 10px; /* Add space between cards */
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
